


























































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "PraxisEditor",
  data() {
    return {
      doctorList: [],
      nurseList: [],
      dialogDoctorAdd: false,
      dialogDoctorDelete: false,
      dialogNurseAdd: false,
      dialogNurseDelete: false,
      nurseFormValid: false,
      editedItem: { id: undefined, drId: undefined },
      newEntry: {
        address: {},
      },
      headersDoctor: [
        {
          text: "Fachrichtung",
          align: "start",
          value: "specialization",
        },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        {
          text: "Entfernen",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headersNurse: [
        {
          text: "E-Mail",
          align: "start",
          value: "email",
        },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        {
          text: "Entfernen",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      salutations: [
        { text: "Herr", value: "male" },
        { text: "Frau", value: "female" },
      ],
    };
  },
  mounted() {
    this.updateAllDoctors();
    this.updateAllNurses();
  },
  methods: {
    updateAllDoctors() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/drs",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => (this.doctorList = result))
        .catch((error) => console.log("error", error));
    },
    updateAllNurses() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/nurse/all",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => (this.nurseList = result))
        .catch((error) => console.log("error", error));
    },
    closeDoctorDialog() {
      this.dialogDoctorAdd = false;
      this.dialogDoctorDelete = false;
      this.$nextTick(() => {
        this.editedItem = { id: undefined, drId: undefined };
        this.newEntry = { address: {} };
      });
    },
    saveDoctor() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", this.$store.getters.getToken);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(this.newEntry),
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/dr",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      this.closeDoctorDialog();
      this.updateAllDoctors();
    },
    deleteConfirmDoctor() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/dr/${this.editedItem.drId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      this.closeDoctorDialog();
      this.updateAllDoctors();
    },
    // @ts-expect-error temp calls vuetify interal
    deleteDoctor(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDoctorDelete = true;
    },
    closeNurseDialog() {
      this.dialogNurseAdd = false;
      this.dialogNurseDelete = false;
      this.$nextTick(() => {
        this.editedItem = { id: undefined, drId: undefined };
        this.newEntry = { address: {} };
      });
    },
    saveNurse() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", this.$store.getters.getToken);

      // @ts-expect-error temp calls vuetify interal
      this.$refs.nurseForm.validate();
      let valid = this.nurseFormValid;
      if (!valid) {
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(this.newEntry),
      };

      fetch(
        "https://mdic-backend.herokuapp.com/api/v1/clinic/registration/nurse",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      this.closeNurseDialog();
      this.updateAllNurses();
    },
    deleteConfirmNurse() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/nurse/${this.editedItem.id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
      this.closeNurseDialog();
      this.updateAllNurses();
    },
    // @ts-expect-error temp calls vuetify interal
    deleteNurse(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogNurseDelete = true;
    },
  },
});
